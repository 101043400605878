<template>
<span>
    <v-layout mb-4>
        <v-flex xs10>
            <h2 class="lime--text lighten-1 pt-3 ml-3">Gerenciar Empresa - Edição</h2>
        </v-flex>
        <v-flex xs2 text-right>
            <v-btn color="blue-grey" class="black--text" :to="{name: 'company'}">
                <v-icon dark>keyboard_arrow_left</v-icon>
                Voltar
            </v-btn>
        </v-flex>
    </v-layout>

    <form-company :company="company" :update="true"></form-company>
</span>
</template>

<script>
import FormCompanyComponent from './partial/FormCompanyComponent'

export default {
    name: 'AddCompanyComponent',
    created() {
        this.loadCompany()
    },
    props: {
        comp_id: {
            require: true
        }
    },
    data() {
        return {
            company: {
                comp_id: '',
                language_id: 1,
                comp_razaosocial: '',
                comp_fantasia: '',
                comp_copyright: '',
                comp_dominio: '',
                comp_google_analytics: '',
                comp_obs: '',
                comp_descricao: '',
                comp_palavras_chaves: '',
                comp_link_facebook: '',
                comp_link_instagram: '',
                comp_link_twitter: '',
                comp_status: '',
                comp_logo: ''
            },
            upload: null,
            fileName: 'Selecione uma foto',
            preview: null,
            langs: true
        }
    },
    methods: {
        loadCompany() {
            this.$store.dispatch('loadCompany', this.comp_id)
                .then((response) => {
                    this.company = response
                })
                .catch(error => {
                    this.$swal({
                        position: 'center',
                        icon: 'error',
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    })
                })
        }
    },
    components: {
        formCompany: FormCompanyComponent
    }
}
</script>

<style scoped>

</style>
